import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import React from "react";
import Layout from "../components/layout/layout";
import PageHero from "../components/pageHero/pageHero";
import JSONData from "../../content/ourWorkPageHeroData.json"
import {graphql, useStaticQuery} from "gatsby";
import OurWorkCardsSection from "../components/ourWorkCardsSection/ourWorkCardsSection";
import Cta from "../components/cta/cta";
import {GatsbyImage} from "gatsby-plugin-image";
import * as styles from "../styles/ourwork.module.scss"
import {Helmet} from "react-helmet";

export default function OurWorkPage() {

    const data = useStaticQuery(graphql`{
         clientImage: file(relativePath: {eq: "our-work/clients/image-3.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: CONSTRAINED)
            }
        }
    }`);

    let tempLogos = [];

    for (let i = 0; i < 15; i++) {
        tempLogos.push(<div className={styles.imageWrapper} key={i}>
            <GatsbyImage className={styles.image} alt={"clients"}
                         image={data.clientImage.childImageSharp.gatsbyImageData}/>
        </div>);
    }
    return <>
        <Helmet>
            <meta charSet="utf-8" />
            <html lang="en" />
            <title>Our work - Recoded Recruitment</title>
        </Helmet>
        <Layout
            header={<Header transparent={true}/>}
            footer={<Footer/>}
        >
            <PageHero textMaxWidth={"650px"} text={JSONData.text} sectionTitle={JSONData.sectionTitle}
                      header={JSONData.header}/>
            <OurWorkCardsSection/>
            {
                /* <div className={styles.images}>
                    {tempLogos}
                </div> */
            }
            {/* <Cta buttonText={"Book a call"}/> */}
        </Layout>
    </>
}
